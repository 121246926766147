import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from "./Facebook"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
            facebook
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const image = site.siteMetadata.image ? `${site.siteMetadata.siteUrl}${site.siteMetadata.image}` : null
  
  return (
    <>
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                name: `description`,
                content: metaDescription,
                },
                {
                name: "keywords",
                content: site.siteMetadata.keywords.join(","),
                },
            ].concat(meta)}>
                <script type="application/ld+json">
                {`
                    {
                    "@context": "https://schema.org",
                    "@type": "MedicalClinic",
                    "name": "${site.siteMetadata.title}",
                    "image": "${image}",
                    "@id": "${site.siteMetadata.siteUrl}",
                    "url": "${site.siteMetadata.siteUrl}",
                    "telephone": "+436763736655",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Schulgasse 42",
                        "addressLocality": "Oberwart",
                        "postalCode": "7400",
                        "addressCountry": "AT"
                    } ,
                    "sameAs": "${site.siteMetadata.facebook}"
                    }
                `}
                </script>
        </Helmet>
  
        <Facebook
            url={site.siteMetadata.siteUrl}
            name={title}
            locale={lang}
            desc={metaDescription}
            title={title}
            image={image}
        />
    </>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default SEO