import * as React from 'react'
import { Link } from 'gatsby'
import { Container, Nav, Navbar } from 'react-bootstrap'
import LinkFade from "../components/TransitionLinks/LinkFade";

export default function Layout({ children, lang, location }) {

  let de_link: string, hu_link: string;
  if (lang === 'de') {
    de_link = location.pathname;
    hu_link = `/hu${location.pathname}`;
  } else {
    de_link = location.pathname.replace('/' + lang + '/', '/');
    hu_link = location.pathname;
  }

  return (
    <div id="wrapper">
      <header style={{borderBottom: '3px solid var(--secondary-color)', fontSize: "1.2rem"}} >
        <Container>
          <Navbar expand="lg" style={{paddingBottom: 10, paddingTop: 10}}>
            <Navbar.Brand>
              <LinkFade to={lang === 'de' ? `/` : `/${lang}/`} style={{color: "var(--secondary-color)", fontWeight: 600, fontSize: "1.4rem"}}>Dr. {lang === 'de' ? 'Zoltán Tihanyi' : 'Tihanyi Zoltán'}</LinkFade>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="ms-auto">
                <Nav.Item >
                  <LinkFade to={lang === 'de' ? `/` : `/${lang}/`} className="nav-link" activeClassName="custom">{lang === 'de' ? 'STARTSEITE' : 'KEZDŐLAP'}</LinkFade>
                </Nav.Item>
                <Nav.Item >
                  <LinkFade to={lang === 'de' ? `/ueber-mich` : `/${lang}/ueber-mich`} className="nav-link" activeClassName="custom">{lang === 'de' ? 'ÜBER MICH' : 'RÓLAM'}</LinkFade>
                </Nav.Item>
                <Nav.Item >
                  <LinkFade to={lang === 'de' ? `/leistungen` : `/${lang}/leistungen`} className="nav-link" activeClassName="custom">{lang === 'de' ? 'LEISTUNGEN' : 'SZOLGÁLTATÁSOK'}</LinkFade>
                </Nav.Item>
                <Nav.Item >
                  <LinkFade to={lang === 'de' ? `/kontakt` : `/${lang}/kontakt`} className="nav-link" activeClassName="custom">{lang === 'de' ? 'KONTAKT' : 'KAPCSOLAT'}</LinkFade>
                </Nav.Item>
                <Nav.Item className='language-selector-nav'>
                  <LinkFade to={de_link} className="nav-link language-selector-item" activeClassName="custom">DEUTSCH</LinkFade>
                  /
                  <LinkFade to={hu_link} className="nav-link language-selector-item" activeClassName="custom">MAGYAR</LinkFade>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
      <main>
        {children}
      </main>
      <footer></footer>
    </div>
  )
}